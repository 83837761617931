<!-- eslint-disable vue/valid-v-slot -->
<template>
  <div>
    <v-container class="UserGuideList">
      <v-tabs>
        <v-tab><h3>User Guides</h3></v-tab>
      </v-tabs>
      <v-row>
        <v-col cols="2">
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Search User Guides"
            hide-details
          ></v-text-field>
      </v-col>
      </v-row>
      <v-row>
        <v-data-table
          style="width: 100%;"
          :headers="headers"
          :items= "userGuides"
          :footer-props="{
            'items-per-page-options': [5, 10, 15, 25, -1],
          }"
          :items-per-page="25"
          :search="search"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          dense
          class="elevation-1"
          >
          <template #item.pdf="{ value }">
            <a target="_blank" :href="value.url">
              {{ value.pdfName }}
            </a>
          </template>
          <template #item.video="{ value }">
            <a target="_blank" :href="value">
              {{ value }}
            </a>
          </template>
        </v-data-table>
    </v-row>
    </v-container>
</div>
</template>

<script>
import {
  mapActions,
  mapState,
} from 'vuex';

export default {
  name: 'UserGuideList',
  data() {
    return {
      search: '',
      headers: [
        {
          text: 'Topic',
          align: 'start',
          sortable: true,
          value: 'topic',
        },
        { text: 'Category', value: 'category' },
        { text: 'PDF', value: 'pdf' },
        { text: 'Video', value: 'video' },
      ],
      sortBy: ['topic'],
      sortDesc: [false],
    };
  },
  methods: {
    ...mapActions('userGuide', [
      'listBlobs',
    ]),
  },
  computed: {
    ...mapState({
      userGuides: (state) => state.userGuide.userGuides,
    }),
  },
  mounted() {
    this.listBlobs();
  },
};

</script>
