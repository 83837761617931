<template>
 <UserGuideList />
</template>

<script>

import UserGuideList from '@/components/common/user-guide/UserGuideList.vue';

export default {
  name: 'UserGuide',
  components: {
    UserGuideList,
  },
};
</script>
